import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDialog } from "../../dialog/DialogProvider";
import NoticeAPI from "../../apis/NoticeAPI";
import OneButtonAlertDialog from "../../dialog/OneButtonAlertDialog";
import FileUpload from "../../components/common/FileUpload";

const SubLocationList = [
  { href: "/commu_02/", text: "소통마당" },
  // { href: '/reward_02/', text: '참여마당' },
  // { href: '/info_02/', text: '정보마당' },
];

const Write_NoticePage = () => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  // 첨부파일
  const [uploadFiles, setUploadFiles] = useState([] as File[]);
  const [docFiles, setDocFiles] = useState([] as File[]);

  const [initialImageFiles, setInitialImageFiles] = useState<any[]>([]);
  const [initialDocFiles, setInitialDocFiles] = useState<any[]>([]);

  const handleSubmit = () => {
    if (title == "") {
      const title = "공지사항";
      const message = "제목을 입력해주세요.";
      showDialog(OneButtonAlertDialog, { title, message });
      return;
    }
    if (content == "") {
      const title = "공지사항";
      const message = "내용을 입력해주세요.";
      showDialog(OneButtonAlertDialog, { title, message });
      return;
    }

    NoticeAPI.insertNotice(
      {
        title: title,
        content: content,
      },
      uploadFiles.length !== 0 ? uploadFiles : undefined,
      docFiles ? docFiles : undefined
    )
      .then((res) => {
        if (res.data.type == 1) {
          const title = "공지사항 등록";
          const message = "등록되었습니다.";
          showDialog(OneButtonAlertDialog, {
            title,
            message,
            handleDialogResult(result) {
              if (result === "ok") {
                navigate("/notice_02/list");
              }
            },
          });
        } else {
          const title = "등록 실패";
          const message = res.data.message || "알 수 없는 오류가 발생했습니다.";
          showDialog(OneButtonAlertDialog, {
            title,
            message,
          });
        }
      })
      .catch((error) => {
        const title = "등록 실패";
        const message =
          error.response.data.message || "알 수 없는 오류가 발생했습니다.";
        showDialog(OneButtonAlertDialog, {
          title,
          message,
        });
      });
  };

  // 취소 버튼
  const handleCancle = () => {
    navigate(-1);
  };

  return (
    <main>
      <h1 className="notext">전체 영역</h1>
      <nav id="lnb">
        <h2 className="notext">네비게이션 영역</h2>
        {/* <SubLocation titleText1="소통마당" liList={SubLocationList} /> */}
        <div className="lnb_txt">
          <h3>공지사항 등록하기</h3>
          {/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
        </div>
      </nav>
      <article id="container">
        <h2 className="notext">컨텐츠 영역</h2>
        {/* 해당 페이지의 시작입니다 */}
        <div id="content">
          <form>
            <div className="comm_write_wrap">
              <div className="cont-wrap">
                <h3 className="important">제목</h3>
                <div className="cont-box">
                  <input
                    type="text"
                    className="long"
                    placeholder="제목을 입력하세요"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="cont-wrap">
                <h3 className="important">내용</h3>
                <div className="cont-box">
                  <textarea
                    placeholder="내용을 입력하세요"
                    defaultValue={""}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
              </div>
              <div className="cont-wrap">
                <FileUpload
                  type="image"
                  label="첨부 이미지"
                  maxFileSize={15000000}
                  maxFileCount={5}
                  accept="image/*"
                  onFilesChange={(files: File[]) => {
                    setUploadFiles(files);
                  }}
                  initialFiles={initialImageFiles}
                  errorHandler={(message) => {
                    showDialog(OneButtonAlertDialog, {
                      title: "",
                      message,
                    });
                  }}
                />

                <FileUpload
                  type="document"
                  label="파일첨부(PDF, HWP)"
                  maxFileSize={15000000}
                  maxFileCount={5}
                  accept=".pdf,.hwp"
                  onFilesChange={(files: File[]) => {
                    setDocFiles(files);
                  }}
                  initialFiles={initialDocFiles}
                  errorHandler={(message) => {
                    showDialog(OneButtonAlertDialog, {
                      title: "",
                      message,
                    });
                  }}
                />
              </div>
              <div className="comm_btn_wrap">
                <button
                  type="button"
                  className="btn_cancel"
                  onClick={handleCancle}
                >
                  취소
                </button>
                <button
                  type="button"
                  className={`btn_next ${content !== "" ? "on" : ""}`}
                  onClick={handleSubmit}
                  // disabled={content !== '' ? false : true}
                >
                  등록
                </button>{" "}
                {/* 모두 체크 시 on클래스 붙음*/}
              </div>
            </div>
          </form>
        </div>
        {/* 해당 페이지의 종료입니다 */}
      </article>
    </main>
  );
};

export default Write_NoticePage;
