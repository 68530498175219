import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDialog } from "../../dialog/DialogProvider";
import NoticeAPI from "../../apis/NoticeAPI";
import OneButtonAlertDialog from "../../dialog/OneButtonAlertDialog";
import FileUpload from "../../components/common/FileUpload";

const SubLocationList = [
  { href: "/commu_02/", text: "소통마당" },
  // { href: '/reward_02/', text: '참여마당' },
  // { href: '/info_02/', text: '정보마당' },
];

const Modify_NoticePage = () => {
  const uid = sessionStorage.getItem("noticeN");
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const [uploadFiles, setUploadFiles] = useState([] as File[]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState([] as number[]);
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  // 첨부파일
  const [docFiles, setDocFiles] = useState([] as File[]);
  type PreviewFile = string | { file: File; preview: string; name: string };
  const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const [initialImageFiles, setInitialImageFiles] = useState<any[]>([]);
  const [initialDocFiles, setInitialDocFiles] = useState<any[]>([]);

  useEffect(() => {
    NoticeAPI.getNoticeDetail({
      nntUid: Number(uid),
    }).then((res) => {
      if (res) {
        setTitle(res.title);
        setContent(res.content);
        // 사진 조회 api추가되면 수정
        if (res?.fileList) {
          setFileList(res.fileList);

          const imageFiles = res.fileList.filter(
            (file) =>
              file.fileName.includes(".png") || file.fileName.includes(".jpg")
          );
          const docFiles = res.fileList.filter(
            (file) =>
              file.fileName.includes(".pdf") || file.fileName.includes(".hwp")
          );

          setInitialImageFiles(imageFiles);
          setInitialDocFiles(docFiles);
        }
      }
    });
  }, []);

  const handleModify = () => {
    if (title == "") {
      const title = "공지사항";
      const message = "제목을 입력해주세요.";
      showDialog(OneButtonAlertDialog, { title, message });
      return;
    }
    if (content == "") {
      const title = "공지사항";
      const message = "내용을 입력해주세요.";
      showDialog(OneButtonAlertDialog, { title, message });
      return;
    }

    NoticeAPI.updateNotice(
      {
        nntUid: Number(uid),
        title: title,
        content: content,
      },
      uploadFiles,
      docFiles,
      deleteFileIds
    )
      .then((res) => {
        if (res.data.type == 1) {
          // console.log("공지사항 수정", res)
          const title = "공지사항 수정";
          const message = "수정되었습니다.";
          showDialog(OneButtonAlertDialog, {
            title,
            message,
            handleDialogResult(result) {
              if (result === "ok") {
                navigate("/notice_02/notice_03-detail");
              }
            },
          });
        } else {
          const title = "등록 실패";
          const message = res.data.message || "알 수 없는 오류가 발생했습니다.";
          showDialog(OneButtonAlertDialog, {
            title,
            message,
          });
        }
      })
      .catch((error) => {
        const title = "등록 실패";
        const message =
          error.response.data.message || "알 수 없는 오류가 발생했습니다.";
        showDialog(OneButtonAlertDialog, {
          title,
          message,
        });
      });
  };

  // 취소 버튼
  const handleCancle = () => {
    navigate(-1);
  };

  return (
    <main>
      <h1 className="notext">전체 영역</h1>
      <nav id="lnb">
        <h2 className="notext">네비게이션 영역</h2>
        {/* <SubLocation titleText1="소통마당" liList={SubLocationList} /> */}
        <div className="lnb_txt">
          <h3>공지사항 수정하기</h3>
          {/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
        </div>
      </nav>
      <article id="container">
        <h2 className="notext">컨텐츠 영역</h2>
        {/* 해당 페이지의 시작입니다 */}
        <div id="content">
          <form>
            <div className="comm_write_wrap">
              <div className="cont-wrap">
                <h3 className="important">제목</h3>
                <div className="cont-box">
                  <input
                    type="text"
                    className="long"
                    placeholder="제목을 입력하세요"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="cont-wrap">
                <h3 className="important">내용</h3>
                <div className="cont-box">
                  <textarea
                    placeholder="내용을 입력하세요"
                    defaultValue={""}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
              </div>
              <FileUpload
                type="image"
                label="첨부 이미지"
                maxFileSize={15000000}
                maxFileCount={5}
                accept="image/*"
                onFilesChange={(files: File[]) => {
                  setUploadFiles(files);
                }}
                onDeleteIds={(ids) => {
                  setDeleteFileIds(ids);
                }}
                initialFiles={initialImageFiles}
                errorHandler={(message) => {
                  showDialog(OneButtonAlertDialog, {
                    title: "",
                    message,
                  });
                }}
              />

              <FileUpload
                type="document"
                label="파일첨부(PDF, HWP)"
                maxFileSize={15000000}
                maxFileCount={5}
                accept=".pdf,.hwp"
                onFilesChange={(files: File[]) => {
                  setDocFiles(files);
                }}
                onDeleteIds={(ids) => {
                  setDeleteFileIds(ids);
                }}
                initialFiles={initialDocFiles}
                errorHandler={(message) => {
                  showDialog(OneButtonAlertDialog, {
                    title: "",
                    message,
                  });
                }}
              />
            </div>
            <div className="comm_btn_wrap">
              <button
                type="button"
                className="btn_cancel"
                onClick={handleCancle}
              >
                취소
              </button>
              <button
                type="button"
                className={`btn_next ${content !== "" ? "on" : ""}`}
                disabled={content !== "" ? false : true}
                onClick={handleModify}
              >
                수정
              </button>{" "}
              {/* 모두 체크 시 on클래스 붙음*/}
            </div>
          </form>
        </div>
        {/* 해당 페이지의 종료입니다 */}
      </article>
    </main>
  );
};

export default Modify_NoticePage;
