import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";

interface FileUploadProps {
  maxFileSize?: number;
  maxFileCount?: number;
  accept?: string;
  onFilesChange?: (files: File[]) => void;
  onDeleteIds?: (ids: number[]) => void;
  label?: string;
  sublabel?: string;
  errorHandler?: (message: string) => void;
  type: "image" | "document";
  initialFiles?: Array<{
    fileName: string;
    savePath: string;
    ntfUid: number;
  }>;
}

interface FilePreview {
  url: string;
  name: string;
  file?: File; // 새로 추가된 파일의 경우 File 객체 저장
  boardFileId?: number; // 기존 파일의 경우 ID 저장
}

const FileUpload: React.FC<FileUploadProps> = ({
  maxFileSize = 15000000,
  maxFileCount = 5,
  accept = "image/*",
  onFilesChange,
  onDeleteIds,
  label = "파일 첨부",
  sublabel = `(최대 ${maxFileCount}장)`,
  errorHandler,
  type,
  initialFiles = [],
}) => {
  const [previews, setPreviews] = useState<FilePreview[]>([]);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);

  useEffect(() => {
    // 초기 파일들의 미리보기 설정
    const initialPreviews: FilePreview[] = initialFiles.map((file) => ({
      url: `/api/attach/getFile?savedPath=${file.savePath}&boardFileId=${file.ntfUid}`,
      name: file.fileName,
      boardFileId: file.ntfUid,
    }));
    setPreviews(initialPreviews);
  }, [initialFiles]);

  const resizeImage = (file: File): Promise<Blob> =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        "JPEG",
        100,
        0,
        (uri) => {
          if (uri) {
            resolve(uri as Blob); // 리사이즈된 Blob을 반환
          } else {
            reject("이미지 리사이징에 실패했습니다.");
          }
        },
        "blob"
      );
    });

  const validateFile = (file: File): string | null => {
    if (file.size > maxFileSize) {
      return `파일 크기는 최대 ${maxFileSize / 1000000}MB까지만 가능합니다.`;
    }

    if (type === "image" && !["image/png", "image/jpeg"].includes(file.type)) {
      return "파일 형식은 png, jpg만 등록 가능합니다.";
    }

    if (
      type === "document" &&
      !(file.type === "application/pdf" || file.name.endsWith(".hwp"))
    ) {
      return "파일 형식은 pdf, hwp만 등록 가능합니다.";
    }

    return null;
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;
      if (!files) return;

      const selectedFiles = Array.from(files);

      if (previews.length + selectedFiles.length > maxFileCount) {
        throw new Error(
          `파일은 최대 ${maxFileCount}개까지 업로드할 수 있습니다.`
        );
      }

      for (const file of selectedFiles) {
        const errorMessage = validateFile(file);
        if (errorMessage) {
          throw new Error(errorMessage);
        }
      }

      const newPreviews: FilePreview[] = [];

      for (const file of selectedFiles) {
        if (type === "image") {
          const resizedBlob = await resizeImage(file);
          const resizedFile = new File([resizedBlob], file.name, {
            type: file.type,
          });
          const resizedUrl = URL.createObjectURL(resizedBlob);
          newPreviews.push({
            url: resizedUrl, // 리사이즈된 이미지를 미리보기 URL로 설정
            name: file.name,
            file: resizedFile, // 리사이즈된 파일 객체 저장
          });
        } else {
          const fileUrl = URL.createObjectURL(file);
          newPreviews.push({
            url: fileUrl,
            name: file.name,
            file: file,
          });
        }
      }

      setPreviews((prev) => [...prev, ...newPreviews]);

      // 새로운 File 객체들만 모아서 onFilesChange 호출
      if (onFilesChange) {
        const allFiles = [...previews, ...newPreviews]
          .filter((preview) => preview.file)
          .map((preview) => preview.file!);
        onFilesChange(allFiles);
      }
    } catch (error) {
      if (errorHandler && error instanceof Error) {
        errorHandler(error.message);
      }
    }

    event.target.value = "";
  };

  const handleRemove = (index: number) => {
    const removedPreview = previews[index];

    if (removedPreview.boardFileId !== undefined) {
      setDeleteIds((cur) => {
        const newDeleteIds = Array.from(
          new Set([...cur, removedPreview.boardFileId!])
        );
        console.log("삭제할 파일 ID 목록:", newDeleteIds);

        if (onDeleteIds) {
          onDeleteIds(newDeleteIds);
        }

        return newDeleteIds;
      });
    }

    console.log("삭제할 파일 ID:", removedPreview.boardFileId);

    // Local URL인 경우에만 revoke
    if (!removedPreview.url.startsWith("/api/")) {
      URL.revokeObjectURL(removedPreview.url);
    }

    setPreviews((prev) => {
      const newPreviews = prev.filter((_, i) => i !== index);

      // 남아있는 File 객체들만 모아서 onFilesChange 호출
      if (onFilesChange) {
        const remainingFiles = newPreviews
          .filter((preview) => preview.file)
          .map((preview) => preview.file!);
        onFilesChange(remainingFiles);
      }

      return newPreviews;
    });
  };

  return (
    <div className="cont-wrap">
      <h3>
        {label}
        <br />
        (최대 15MB)
      </h3>
      <div className="cont-box">
        <div className={type === "image" ? "imgBox" : "fileBox"}>
          <input
            className={type === "image" ? "upload-photo" : "upload-name"}
            value=""
            placeholder=""
            style={type === "document" ? { display: "none" } : undefined}
          />
          <label htmlFor={`file-${type}`}>
            {type === "image" && (
              <>
                <img src="/path/to/camera-icon.png" alt="파일 첨부" />
                {label}
                <span>{sublabel}</span>
              </>
            )}
            {type === "document" && "파일 선택"}
          </label>
          <input
            type="file"
            id={`file-${type}`}
            accept={accept}
            onChange={handleFileChange}
          />
        </div>

        {type === "image" ? (
          <div className="imglistBox">
            <ul className="no-scroll">
              {previews.map((preview, index) => (
                <li key={index}>
                  <img src={preview.url} alt={preview.name} />
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className="btn-file-delet"
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="filelistBox mt10">
            <ul>
              {previews.map((preview, index) => (
                <li key={index}>
                  <p className="txt_cut1">{preview.name}</p>
                  <p className="delet">
                    <button type="button" onClick={() => handleRemove(index)}>
                      삭제
                    </button>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
